//引入刚才的http.js文件
import http from "../utils/request";

//设置个对象
let apiFun = {};

//消息列表
apiFun.userLeaveMessage = function (params) {
  return http.post("/userLeaveMessage/insert", params);
  // return http.post("/userLeaveMessage/insert", params);
};

apiFun.homePageConfig = function (params) {
  return http.post("/homePageConfig/list", params);
  // return http.post("/homePageConfig/list", params);
};

//暴露出这个对象
export default apiFun;
