import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fullSVGAList: [],
    batterSVGAList: [],
    privateRoomList: [],
    beseUrl: '',
  },
  mutations: {
    setFullSVGAList(state, data) {
      state.fullSVGAList = data
    },
    setBatterSVGAList(state, data) {
      state.batterSVGAList = data
    },
    setPrivateRoomList(state, data) {
      state.privateRoomList = data
    },
    setBeseUrl(state, data) {
      state.beseUrl = data
    },

  },
  actions: {},
  modules: {}
})