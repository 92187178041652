import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/index",
  },

  {
    path: "/index",
    name: "index",
    // redirect: "/index",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "*",
    redirect: "/index",
  },
];

const router = new VueRouter({
  routes,
});

export default router;
