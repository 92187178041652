import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// //引入iview
// import iView from "iview";
// import "iview/dist/styles/iview.css"; // 使用 CSS
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

//引入全局样式
import "../src/assets/css/reset.css";

import http from "@/api/api.js";
Vue.prototype.$http = http; //请求接口api

// var sensors = require("sa-sdk-javascript");
// sensors.init({
//   server_url:
//     "https://xuhuanjuzhen.datasink.sensorsdata.cn/sa?project=production&token=843f241c5b87d4b3",
//   show_log: true,
//   is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
//   use_client_time: true,
//   send_type: "beacon",
//   heatmap: {
//     //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
//     clickmap: "default",
//     //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
//     scroll_notice_map: "not_collect",
//   },
// });

// sensors.quick("autoTrack"); //用于采集 $pageview 事件。

// Vue.prototype.$sensors = sensors;

Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
